import { toast } from 'react-toastify';
import { NewsData } from '#types/api.types';
import { createNews, deleteNews, editNews, getNews } from 'src/utils/api/news';
import { create } from 'zustand';

import { Alert } from 'src/components/alert/alert';

interface INewsStore {
  news: NewsData[];
  totalCount: number;
  currentPage: number;
  totalPages: number;
  mode: 'user' | 'admin';
  isMobile: boolean;

  actions: {
    firstFetchNews: (mode: 'user' | 'admin', isMobile?: boolean) => void;
    fetchNews: (currentPage: number, mode: 'user' | 'admin', isMobile?: boolean) => void;
    createNews: (data: Partial<NewsData>) => void;
    deleteNews: (id: number) => void;
    editNews: (data: Partial<NewsData>) => void;
    hiddenNews: (data: NewsData) => void;
  };
}

const useNewsStore = create<INewsStore>((set, get) => ({
  news: [],
  totalCount: 0,
  currentPage: 0,
  totalPages: 0,
  mode: 'user',
  isMobile: false,

  actions: {
    firstFetchNews: (mode, isMobile) => {
      const { news } = get();

      const limit = isMobile ? 5 : 15;
      getNews(0, mode, limit).then((res) => {
        const totalCount = res?.data.total_count ?? 0;
        const totalPages = Math.ceil(totalCount / limit);
        const news = res?.data.data ?? [];
        set({ news, totalCount, totalPages, isMobile });
      });
    },

    fetchNews: async (currentPage, mode, isMobile) => {
      const limit = isMobile ? 5 : 15;

      getNews(currentPage, mode, limit).then((res) => {
        const totalCount = res?.data.total_count ?? 0;
        const totalPages = Math.ceil(totalCount / limit);

        set((state) => {
          const news =
            currentPage !== 0 && isMobile
              ? [...state.news, ...(res?.data.data ?? [])]
              : (res?.data.data ?? []);
          return { news, totalCount, totalPages, isMobile };
        });
      });
    },
    editNews: (data) => {
      editNews(data).then((news) => {
        set((prev) => ({ news: prev.news.map((d) => (d.id === news.id ? news : d)) }));

        const toastId = String(Date.now());
        toast(
          Alert({
            alertId: toastId,
            type: "success",
            title: "Успішно",
            description: "Новина успішно оновлена"
          }), {
          position: "top-right",
          toastId: toastId
        });
      }).catch((err) => {
        toast(
          Alert({
            alertId: err.message,
            type: "error",
            title: "Не успішно",
            description: ""
          }), {
          position: "top-right",
          toastId: err.message
        });
      });
    },
    hiddenNews: (data) => {
      const { isMobile } = get();
      editNews(data).then((news: NewsData) => {
        const limit = isMobile ? 5 : 15;
        getNews(0, 'admin', limit).then((res) => {
          const totalCount = res?.data.total_count ?? 0;
          const totalPages = Math.ceil(totalCount / limit);
          set((state) => {
            const news = isMobile
              ? [...state.news, ...(res?.data.data ?? [])]
              : (res?.data.data ?? []);
            return { news, totalCount, totalPages };
          });
        });

        const toastId = String(Date.now());
        toast(
          Alert({
            alertId: toastId,
            type: "success",
            title: "Успішно",
            description: "Новина успішно оновлена"
          }), {
          position: "top-right",
          toastId: toastId
        });
      }).catch((err) => {
        toast(
          Alert({
            alertId: err.message,
            type: "error",
            title: "Не успішно",
            description: ""
          }), {
          position: "top-right",
          toastId: err.message
        });
      });
    },
    createNews: (data) => {
      const { totalCount, news, isMobile } = get();
      createNews(data).then((res: NewsData) => {
        const limit = isMobile ? 5 : 15;
        getNews(0, 'admin', limit);
        const total = res.active ? totalCount + 1 : totalCount - 1;
        const totalPages = Math.ceil(total / limit);
        news.unshift(res);
        set({ totalCount: total, news, totalPages });

        const toastId = String(Date.now());
        toast(
          Alert({
            alertId: toastId,
            type: "success",
            title: "Успішно",
            description: "Новина успішно створена"
          }), {
          position: "top-right",
          toastId: toastId
        });
      }).catch((err) => {
        toast(
          Alert({
            alertId: err.message,
            type: "error",
            title: "Не успішно",
            description: ""
          }), {
          position: "top-right",
          toastId: err.message
        });
      });
    },

    deleteNews: (id) => {
      const { news, totalCount: totalC, isMobile } = get();
      deleteNews(id).then(() => {
        const limit = isMobile ? 5 : 15;

        const updateNews = news.filter((n) => n.id !== id);
        const totalCount = totalC - 1;
        const totalPages = Math.ceil(totalCount / limit);
        set({ news: updateNews, totalCount, totalPages });

        const toastId = String(Date.now());
        toast(
          Alert({
            alertId: toastId,
            type: "success",
            title: "Успішно",
            description: "Новина успішно видалена"
          }), {
          position: "top-right",
          toastId: toastId
        });
      }).catch((err) => {
        toast(
          Alert({
            alertId: err.message,
            type: "error",
            title: "Не успішно",
            description: ""
          }), {
          position: "top-right",
          toastId: err.message
        });
      });
    },
  },
}));

export const useNewsActions = () => useNewsStore((state) => state.actions);
export const useNews = () => useNewsStore((state) => state.news);
export const useCount = () => useNewsStore((state) => state.totalCount);
export const useTotalPages = () => useNewsStore((state) => state.totalPages);
export const useTotalCount = () => useNewsStore((state) => state.totalCount);
