import { authorizationAxios, instance } from './instance';
import type { FormData, ResetPassword, RestorePassword } from 'src/types/auth.types';
import type { AxiosResAuthLoginType, AxiosResAuthUserType } from 'src/types/api.types';
import { delItem, getItem, setItem } from '../storage';
import { AxiosError, isAxiosError } from 'axios';

enum apiReq {
  User = 'auth/me',
  SignIn = 'auth/login',
  Logout = 'auth/logout',
  Reset = 'auth/password/reset',
  Restore = 'auth/password/restore',
}

const authLogin = async (data: FormData, rememberMe: boolean) => {
  try {
    const response: AxiosResAuthLoginType = await instance.post(apiReq.SignIn, data);
    authorizationAxios(response.data.access_token);
    setItem(response.data.access_token, rememberMe);
    return response;
  } catch (err) {
    if (isAxiosError(err)) throw new AxiosError(err.message);
  }
};

const authUser = async () => {
  const access_token = getItem();
  try {
    if (access_token) {
      authorizationAxios(access_token);
      const response: AxiosResAuthUserType = await instance(apiReq.User);
      return response;
    }
  } catch (err) {
    if (access_token) delItem();
    if (isAxiosError(err)) throw new AxiosError(err.message);
  }
};

const authLogout = () => {
  instance.post(apiReq.Logout);
  authorizationAxios(null);
  delItem();
};

const authResetPassword = async (data: ResetPassword) => {
  try {
    const response = await instance.post(apiReq.Reset, data);
    return response;
  } catch (err: any) {
    if (isAxiosError(err)) throw new AxiosError((err?.response?.data?.details || [])[0]?.message);
  }
};

const authRestorePassword = async (data: RestorePassword) => {
  try {
    const response = await instance.post(apiReq.Restore, data);
    return response;
  } catch (err) {
    if (isAxiosError(err)) throw new AxiosError(err.message);
  }
};

export { authLogin, authUser, authLogout, authResetPassword, authRestorePassword };
