import { sizes } from 'src/utils/consts/sizes';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonHoverStyle } from '../buttons/Button.styled';


export const AsideContainer = styled.div<{ $isActive: boolean }>`
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    width: ${(props) => (props.$isActive ? '285px' : '95px')};
    min-width: ${(props) => (props.$isActive ? '285px' : '95px')};

    overflow: auto;
    height: 100vh;
`;

export const IconBox = styled.div<{ $isActive: boolean }>`
    background-color: ${(props) => (props.$isActive ? 'var(--orange-3)' : '')};
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AsideBox = styled.div<{ $isActive: boolean }>`
  background-color: #fff;

  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (${sizes.min_tablet}) {
    position: relative;
    // height: 100%;
    min-height: 100%;
    transition: width var(--animate);
    width: ${(props) => (props.$isActive ? '267px' : '72px')};
    min-width: ${(props) => (props.$isActive ? '267px' : '72px')};
    display: flex;
    flex-direction: column;
    ${(props) =>
      props.$isActive
        ? css`
            padding-left: 22px;
          `
        : css`
            align-items: center;
          `}
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 100;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
  }
`;

export const LogoBoxStyle = styled.div<{ $isActive: boolean }>`
  height: ${(props) => (props.$isActive ? '32' : '48')}px;
`;

export const ButtonChangeAside = styled.button<{ $isActive: boolean }>`
  position: absolute;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 6px;
  border: 2px solid var(--main-orange);
  background-color: white;
  z-index: 200;
  top: 90px;
  left: 56px;
  transition: left var(--animate);
  ${(props) => props.$isActive && `left: 245px;`};
`;

export const ListPagesStyle = styled.div<{ $isActive: boolean }>`
  margin-top: ${(props) => (props.$isActive ? '82px' : '55px')};
  display: flex;
  flex-direction: column;
`;

export const ListStyle = styled.ul<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    !props.$isActive &&
    css`
      align-items: center;
    `}
  gap: 15px;
  &::after {
    content: '';
    display: block;
    width: ${(props) => (props.$isActive ? '200px' : '24px')};
    height: 1px;
    margin-bottom: 20px;
    border: 1px solid var(--orange-3);
  }
`;

export const ListLiStyle = styled.li<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
`;

export const LinkStyle = styled(Link)<{ $isActive: boolean; $isAside: boolean }>`
  background-color: white;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: ${(props) => (props.$isAside ? '14px' : '0px')};
  font-weight: ${(props) => (props.$isActive ? '700' : 'normal')};
  transition: all var(--animate);
  &:is(:hover, :focus) {
    font-size: 16px;
    color: var(--main-orange);
    svg {
      fill: var(--main-orange);
      stroke: var(--main-orange);
    }
  }
  ${(props) =>
    props.$isActive &&
    css`
      font-size: 16px;
      color: var(--main-orange);
    `}
`;

export const ButtonPanelStyle = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  font-size: 0px;
  color: var(--main-orange);
  transition: all var(--animate);
  ${(props) =>
    props.$isActive &&
    css`
      padding: 12px 24px;
      border-radius: 8px;
      border: 1px solid var(--main-orange);
      font-size: 14px;
      color: var(--orange-2);
    `}
`;

export const ChatBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonExitStyle = styled(ButtonHoverStyle)<{ $isActive: boolean }>`
  display: flex;
  font-size: 0px;
  transition: all var(--animate);
  ${(props) =>
    props.$isActive
      ? css`
          align-items: center;
          justify-content: center;
          padding: 12px 24px;
          margin-top: 20px;
          margin-right: 50px;
          font-size: 14px;
        `
      : css`
          padding: 12px 8px;
          margin-top: 15px;
          svg {
            fill: #fff;
          }
        `}
  &:is(:hover, :focus) {
    svg {
      fill: var(--main-orange);
    }
  }
`;
