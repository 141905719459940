import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import {
  ContainerLoginStyle,
  ImageWrapper,
  WrapperFormStyle,
  BackgroundImageStyle,
  SectionLoginStyle,
  HeaderStyle
} from './LoginLayout.styled';

export default function LoginLayout() {
  return (
    <ContainerLoginStyle>
      <ImageWrapper>
        <BackgroundImageStyle src="images/bg.jpg" alt="Фон" loading="lazy" />
        <HeaderStyle>
          <img src="images/logo.png" width={184} height={48} alt="Логотип" />
        </HeaderStyle>
      </ImageWrapper>
      <SectionLoginStyle>
        <WrapperFormStyle>
          <Suspense>
            <Outlet />
          </Suspense>
        </WrapperFormStyle>
      </SectionLoginStyle>
    </ContainerLoginStyle>
  );
}
