export const social_links = [
  {
    name: 'facebook',
    image_path: 'images/icon_facebook.svg',
    link: 'https://www.facebook.com/startex02',
    isBot: false,
  },
  {
    name: 'instagram',
    image_path: 'images/icon_insta.svg',
    link: 'https://www.instagram.com/star_tex_mats/',
    isBot: false,
  },
  {
    name: 'telegram',
    image_path: 'images/icon_telegram.svg',
    link: 'https://t.me/startexb2b_bot',
    isBot: true,
  },
  {
    name: 'viber',
    image_path: 'images/icon_viber.svg',
    link: 'viber://pa?chatURI=startexb2b',
    isBot: true,
  },
  {
    name: 'tiktok',
    image_path: 'images/icon_tiktok.svg',
    link: 'https://www.tiktok.com/@star_tex',
    isBot: false,
  },
  {
    name: 'youtube',
    image_path: 'images/icon_youtube.svg',
    link: 'https://www.youtube.com/@star-tex-mats',
    isBot: false,
  }
];
