import { Admin, Close, Logo } from '../icons';
import { usePermissions } from 'src/store/authStore';
import { permissionsPages, findPages, adminPanel } from 'src/utils/consts/permissions';
import { useLocation, useNavigate } from 'react-router-dom';
import Messengers from '../links/Messengers';
import {
  BackdropStyle,
  ButtonCloseStyle,
  ButtonLogoutStyle,
  ButtonPanelStyle,
  ChatBoxStyle,
  HeaderBoxStyle,
  LinkStyle,
  ListLiStyle,
  ListStyle,
  ModalStyle,
  IconBox
} from './MobilePopup.styled';
import { useAdminActions, useIsAdminPanel } from 'src/store/adminStore';
import { useNewsActions } from 'src/store/newsStore';
import { useAuth } from 'src/providers/AuthProvider';

export default function MobilePopup({ changeModal, isOpenModal }: { changeModal: () => void, isOpenModal: boolean }) {
  const permissions = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const { handleLogout } = useAuth();
  const { changePanel } = useAdminActions();
  const isAdminPanel = useIsAdminPanel();
  const { fetchNews } = useNewsActions();

  const clickPanel = () => {
    changeModal();
    changePanel();
    updateNews();
  };

  const updateNews = () => {
    if (location.pathname !== '/') return;
    fetchNews(0, !isAdminPanel ? 'admin' : 'user', true);
  };

  const isActive = (path: string) => {
    return location.pathname.includes('catalog') && path.includes('catalog')
      ? true
      : location.pathname === path;
  };

  const defPages = findPages(permissionsPages.default, permissions);
  const admPages = findPages(permissionsPages.admin, permissions);

  return (
    <BackdropStyle isOpenModal={isOpenModal}>
      <ModalStyle className={isOpenModal ? 'opened' : 'closed'}>
        <HeaderBoxStyle>
          <Logo />
          <ButtonCloseStyle type="button" onClick={() => changeModal()}>
            <Close />
          </ButtonCloseStyle>
        </HeaderBoxStyle>
        {defPages.length && (
          <ListStyle>
            {defPages.map(({ title, page, Icon }, i) => {
              const isActiveHover = isActive(page);
              return (
                <ListLiStyle key={i}>
                  <LinkStyle to={page} $isActive={isActiveHover} onClick={() => changeModal()}>
                    {
                      <IconBox $isActive={isActiveHover}>
                        <Icon hover="main-orange" isHover={true} isActive={isActiveHover} />
                      </IconBox>
                    }
                    {title}
                  </LinkStyle>
                </ListLiStyle>
              );
            })}
          </ListStyle>
        )}
        {admPages.length ? (
          <ListStyle>
            {admPages.map(({ title, page, Icon }, i) => {
              const isActiveHover = isActive(page);
              return (
                <ListLiStyle key={i}>
                  <LinkStyle to={page} $isActive={isActiveHover} onClick={() => changeModal()}>
                    {
                      <IconBox $isActive={isActiveHover}>
                        <Icon hover="main-orange" isHover={true} isActive={isActiveHover} />
                      </IconBox>
                    }
                    {title}
                  </LinkStyle>
                </ListLiStyle>
              );
            })}
            {adminPanel(location.pathname, permissions) && (
              <ListLiStyle>
                <ButtonPanelStyle type="button" onClick={() => clickPanel()}>
                  <Admin color="main-orange" />
                  {isAdminPanel ? 'До Юзер-панелі' : 'До Адмін-панелі'}
                </ButtonPanelStyle>
              </ListLiStyle>
            )}
          </ListStyle>
        ) : (
          <>
          {adminPanel(location.pathname, permissions) && (
            <ListStyle>
                <ListLiStyle>
                  <ButtonPanelStyle type="button" onClick={() => clickPanel()}>
                    <Admin color="main-orange" />
                    {isAdminPanel ? 'До Юзер-панелі' : 'До Адмін-панелі'}
                  </ButtonPanelStyle>
                </ListLiStyle>
            </ListStyle>
          )}
          </>
        )}
        <ChatBoxStyle>
          <span>ЧатБот:</span>
          <Messengers chatbots={true} />
        </ChatBoxStyle>
        <ButtonLogoutStyle
          type="button"
          onClick={() => {
            handleLogout();
            navigate('/login');
          }}
        >
          Вийти
        </ButtonLogoutStyle>
      </ModalStyle>
    </BackdropStyle>
  );
}
