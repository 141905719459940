import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ mobile: boolean }>`
  :root {
    --main-color: #42434b;
    --gray-2: #e0e0e0;
    --gray-3: #9e9e9e;
    --gray-4: #d9d9d9;
    --gray-5: #bdbdbd;
    --gray-6: #757575;
    --gray-7: #616161;
    --gray-8: #424242;
    --gray-9: #212121;
    --main-orange: #f1b75a;
    --orange-2: #f4c373;
    --orange-3: #ffefd6;
    --green: #4caf50;
    --success-green: #67C23A;
    --red: #f44336;
    --white: #fff;
    --ghostwhite: #FAFAFA;
    --slightly-sky-blue: #F8F8F8;
    --black: #000;
    --yellow: #FFC620;
    --status-green: #97FDE3;
    --status-border-green: #78D1BB;
    --status-light-green: #d9f6da;
    --status-border-light-green: #64d56a;
    --status-violet: #BEC9FF;
    --status-border-violet: #A1AAD8;
    --status-red: #FFCECA;
    --status-border-red: #FFA199;
    --purple: #d8acec;
    --animate: 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .Toastify__toast-container {
    margin-top: ${(props) => props.mobile ? '20px;' : '0px;'};
    min-width: 350px;
    max-width: 400px;
    width: ${(props) => props.mobile ? '95%' : ''};
    margin: 0px auto;
    right: ${(props) => props.mobile ? '0' : '20px;'};
    top: 20px;
  }

  .Toastify__toast { 
    border-radius: 15px;
    border: 2px solid var(--main-orange);
    min-width: 350px;  
    max-width: 400px;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__progress-bar--wrp {
    visibility: hidden;
  }

  div#root {
    width: 100%;
    height: auto;
  }
  * {
    color: var(--main-color);
    box-sizing: border-box;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  body {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
  }
  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 10px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    -webkit-border-radius: 5px;
    border-radius: 8px;
    background: var(--main-orange);
  }
  ::-webkit-scrollbar:horizontal {
    height: 4px;
  }

  input, textarea {
    border: 1px solid var(--gray-2);
    border-radius: 8px;
  }
  input:is(:hover, :focus), textarea:is(:hover, :focus) {
    border: 1px solid var(--gray-2);
    outline: none;
  }

  input[type=checkbox] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: var(--white);
    font: inherit;
    color: var(--white);
    width: 16px;
    height: 16px;
    border: 2px solid var(--main-color);
    border-radius: 3px;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 7px;
    height: 7px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    transform-origin: bottom left;
    border: 1px solid var(--white);
    background-color: var(--white);

    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:checked {
    background-color: var(--main-orange);
    border: 2px solid var(--main-orange);
  }

  input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);
  
    color: var(--gray-2);
    cursor: not-allowed;
  }

  textarea::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  textarea::-webkit-scrollbar-track {
    background: transparent;
    width: 4px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  textarea::-webkit-scrollbar-thumb {
    width: 4px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--main-orange);
  }

  select::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  select::-webkit-scrollbar-track {
    background: transparent;
    width: 4px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  select::-webkit-scrollbar-thumb {
    width: 4px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--main-orange);
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    input[type='number'] {
    -moz-appearance:textfield;
  }
  button {
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    &:is(:hover, :focus) {
      color: var(--main-orange);
    }
  }
  h1,
  h2,
  h3,
  h4,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
    line-height: 1.2;
  }
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }
  html,
  body {
    height: auto;
    width: 100%;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
  }
  img {
    display: block;
  }

  .container-paginate {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: flex-end;
    margin-top: 32px;
  }
  .paginate {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 1.5;
    padding: 4px;
    color: var(--gray-6);
    border-radius: 8px;
    transition: all var(--animate);
  }
  .paginate-page-link-element {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .paginate:is(:hover, :focus){
    color: var(--white);
    background-color: var(--main-orange);
  }
  .active-paginate {
    color: var(--white);
    background-color: var(--main-orange);
  }
  .next-prev-paginate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    font-size: 16px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 8px;
    color: var(--gray-6);
    transition: all var(--animate);
  }
  .next-prev-paginate:is(:hover, :focus) {
    color: var(--white);
    background-color: var(--main-orange);
  }

    .react-select {
      border: none;
      
      &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        width: 4px;
        -webkit-border-radius: 0px;
        border-radius: 0px;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: var(--main-orange);
      }

      &:hover {
       border: none;
      }
    }

  .action-modal-role-dialog {
    max-width: 1300px;
  }

  .modal.modal-static .modal-dialog {
    transform: scale(1);
  }

  .modal-news-item-dialog {
    max-width: 624px;
  }

  .modal-title.action-modal-role, .modal-title.modal-create-news-item {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
  } 

  .modal-content {
    border-radius: 15px;
  }

  .modal-form-content {
    padding: 20px;
  }

  .modal-body {
    padding-top: 0px;
  }

  .modal-body.edit-brand-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-body.modal-news-read-more-body {
    @media screen and (max-height: 500px) {
      max-height: 250px;
    }
  }

  .modal-news-read-more-dialog .modal-content {
    max-height: 300px;
  }

  .slider-modal-dialog {
    max-width: 600px;
  }

  .article-mats-modal-dialog {
    max-width: 400px;
  }

  .modal-news-read-more-dialog {
    max-width: 444px;
  }

  .modal-header.read-news-modal-header .btn-close {
    margin: 0;
  }

  .slider-modal-dialog .modal-header .btn-close {
    padding: 15px;
  }

  .modal-header .btn-close:focus {
    box-shadow: none;
  }

  .modal-header.change-brand-modal-header {
    padding: 30px 23px;
  }

  .edit-brand-dialog {
    max-width: 582px;
  }

  input.form-control {
    height: 48px;
    width: 100%;
    margin: 0px 0px 10px 0px;
    border-color: var(--main-orange);
  }

  input.form-control.input-with-sign {
    border-left: none;
    padding-left: 0px;
  }

  input.form-control.input-gray {
    border-color: #E5E5EA;
    background-color: #FDFDFE;
  }
  input.form-control.input-gray:focus {
    border-color: var(--gray-5);
  }

  input.form-control:focus, textarea.form-control:focus {
    box-shadow: unset;
    border-color: var(--main-orange);
  }

  .input-group .input-sign {
    border: 1px solid var(--main-orange);
    border-right: none;
    height: 48px;
    background-color: var(--white);
  }

  .input-group .calendar-input-sign {
    border: 1px solid var(--gray-2);
    height: 48px;
    background-color: #FDFDFE;
  }

  input.form-control.datepicker-input {
    border-right: none;
  }
  input.form-control.datepicker-input:focus {
    border: 1px solid var(--gray-2);
    border-right: none;
  }

  label.form-label {
    color: var(--gray-6);
  }

  .news-item-textarea.form-control {
    height: 144px;
    resize: none;
  }

  .pricelist-textarea {
    height: 100px;
    margin-bottom: 10px;
    resize: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .home-form-datepicker-popper-start-date {
    position: absolute !important;
    left: unset !important;
    top: 100px !important;
    transform: unset !important;
  }

  .home-form-datepicker-popper-end-date {
    position: absolute !important;
    left: unset !important;
    top: 200px !important;
    transform: unset !important;
  }

  .react-datepicker {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid var(--gray-4);
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  form .row {
    margin-left: -10px;
    margin-right: -10px;
  }
`;

export default GlobalStyle;
