import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { sizes } from 'src/utils/consts/sizes';
import styled from 'styled-components';
import { Burger, Logo } from '../icons';
import MobilePopup from '../modals/MobilePopup';
import AsideModal from '../aside/Aside';

const maxWidthAllowed = 1920;

const ContainerStyle = styled.div<{ isFullScreen?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px 0px 0px;
  min-height: 100vh;

  max-width: ${(props) => (!props.isFullScreen ? `${maxWidthAllowed}px;` : '')};
  margin-left: ${(props) => (!props.isFullScreen ? 'auto' : '')};
  margin-right: ${(props) => (!props.isFullScreen ? 'auto' : '')};
  width: ${(props) => (props.isFullScreen ? '100%' : '')};


  @media screen and (${sizes.min_tablet}) {
    // flex-direction: row;
    // width: 100%;
  }

  .aside-element {
    position: fixed;
    height: 100%;
    z-index: 100;
  }
`;

const ButtonBurger = styled.button`
  display: flex;
  align-items: center;
  background-color: initial;
  border: none;
  background-color: var(--orange-3);
  border-radius: 50px;
  padding: 10px;
`;

const HeaderBurgerStyle = styled.div<{ $isMobile: boolean }>`
  @media screen and (${sizes.max_mobile}) {
    position: fixed;
    top: 0;
    left: 0;
    width: ${window.innerWidth > maxWidthAllowed ? maxWidthAllowed + 'px' : '100%'};
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 20px 17px;
    z-index: 100;
  }
  @media screen and (${sizes.min_tablet}) {
    display: none;
  }
`;

const SectionContainerStyle = styled.section<{ $location?: boolean }>`
  margin-top: 35px;
  display: flex;
  flex-position: column;
  align-items: center;
  align-self: flex-start;
  justify-content: ${(props) => (props.$location ? 'initial' : 'center')};
  position: relative;

  width: 100%;

  
  @media screen and (${sizes.max_mobile}) {
    padding-left: 10px;
    margin-left: 0px;
  }
`;

export default function HomeLayout() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const location = useLocation();

  const isFullScreen = location.pathname.includes('/catalog/');

  const changeModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const checkLocation = (path: string) => {
    return (location.pathname.includes('catalog') && path.includes('catalog')) ||
      // (location.pathname.includes('pricelist') && path.includes('pricelist')) ||
      (location.pathname.includes('roles') && path.includes('roles')) ||
      (location.pathname.includes('users') && path.includes('users'))
      ? true
      : location.pathname === path;
  };

  useEffect(() => {
    const changeW = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', changeW);
    return () => {
      return window.removeEventListener('resize', changeW);
    };
  }, []);

  useEffect(() => {
    if (isMobile && isOpenModal) document.body.style.overflow = 'hidden';
    if (isMobile && !isOpenModal) document.body.style.overflow = '';
  }, [isMobile, isOpenModal]);

  useEffect(() => {
    // iphone issue - pages are scrolled down a bit after page loads
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContainerStyle id="home-layout" isFullScreen={isFullScreen}>
      {isMobile && (
        <>
          <HeaderBurgerStyle $isMobile={isMobile}>
            <Logo l={true} />
            <ButtonBurger type="button" onClick={() => changeModal()}>
              <Burger />
            </ButtonBurger>
          </HeaderBurgerStyle>
          <MobilePopup changeModal={changeModal} isOpenModal={isOpenModal} />
        </>
      )}
      
      {!isMobile && <AsideModal />}

      <SectionContainerStyle $location={checkLocation(location.pathname)}>
        <Suspense>
          <Outlet />
        </Suspense>
      </SectionContainerStyle>

    </ContainerStyle>
    
    
  );
}
